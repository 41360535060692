import { OrderOrigin } from "~/api/openapi";
import { OrderStatusEnum } from "~/ui/pages/CustomerOrdersPage/OrderStatusBadge/OrderStatusEnum";

export const CustomerOrdersPageDE = {
  pageTitle: "Meine Bestellungen",
  title: "Meine Bestellungen ({{ count }})",
  sort: {
    orderedDate: "Bestellt am",
    orderNumber: "Bestellnummer",
    totalAmount: "Betrag",
    orderStatus: "Bestellstatus",
    customerReference: "Bestellreferenz",
  },
  card: {
    totalAmount: "Betrag",
    orderStatus: "Bestellstatus",
    customerReference: "Bestellreferenz",
  },
  filter: {
    orderedDate: "Bestellt am",
    searchPlaceholder: "Bestellungen durchsuchen (min. 3 Zeichen)",
    title: "Filtern & Sortieren",
    showOnlyUnconfirmedSegmentOn: "Un-/teilbestätigte Artikel",
    showOnlyUnconfirmedSegmentOff: "Alle",
  },

  table: {
    head: {
      orderedDate: "Bestelldatum",
      number: "Bestellung",
      articles: "Artikel",
      amount: "Betrag",
      orderStatus: "Status",
      reference: "Bestellreferenz",
    },
    body: {
      noOrdersFound: "Keine Bestellungen gefunden, die deinen Suchkriterien entsprechen.",
      noOrdersAvailable: "Noch keine Bestellungen vorhanden.",
      originTooltip: {
        API: "Bestellung wurde über die API getätigt.",
      } as Record<OrderOrigin, string>,
      orderStatus: {
        badge: {
          CONFIRMED: "Bestätigt",
          UNCONFIRMED: "Unbestätigt",
          PARTLY_CONFIRMED: "Teilbestätigt",
        } as Record<OrderStatusEnum, string>,
        partlyConfirmedInfo: "{{count}} von {{maxCount}} bestätigt",
      },
    },
  },
  detail: {
    mobile: {
      head: {
        itemCountLabel: "Positionen:",
        itemCountValue: "{{ itemCount }} von {{totalCount}}",
        totalAmountLabel: "Gesamtwert:",
        shipmentToLabel: "Lieferung an:",
        shipmentToValue: "{{name}}<br />{{zipCode}} {{city}}",
        orderRefLabel: "Bestellreferenz:",
      },
      content: {
        quantity: "Bestellmenge",
        acceptedQuantity: "Bestätigte Menge",
        shippedQuantity: "Gelieferte Menge",
        unitPrice: "Einzelpreis",
        discount: "Rabatt",
        itemTotal: "Summe",
      },
    },
    searchPlaceholder: "Bestellpositionen durchsuchen (min. 3 Zeichen)",
    orderNumber: "Bestellung: {{ orderNumber }}",
    itemCount: "Anzahl Positionen: {{ itemCount }} von {{totalCount}}",
    noDataFound: "Keine Bestellpositionen gefunden, die deinen Suchkriterien entsprechen.",
    shipmentTo: "Lieferung an: {{name}}, {{zipCode}} {{city}}",
    orderRef: "Bestellreferenz: {{orderRef}}",
    totalQuantity: "Anzahl gesamt: {{totalQuantity}}",
    totalValue: "Gesamtwert:",
    articleNumber: "{{ articleNumber }}",
    quantity: "Bestellmenge",
    acceptedQuantity: "Bestätigte Menge",
    shippedQuantity: "Gelieferte Menge",
    itemRef: "Artikelreferenz",
    unitPrice: "Einzelpreis",
    discount: "Rabatt",
    itemTotal: "Summe",
    showUnconfirmedArticleToggle: "Nur un-/teilbestätigte Artikel anzeigen",
  },
  csv: {
    downloadFileName: "heo-csv-export-customerorders_{{date}}",
    tooltipDownloadButton: "CSV-Export (Daten aus Suchergebnis)",
    customerOrderNumber: "Bestellnummer",
    customerReference: "Bestellreferenz",
    itemCustomerItemReference: "Artikelreferenz",
    createdAt: "Bestelldatum",
    total: "Betrag Bestellung",
    shippingAddress: "Lieferadresse",
    itemQuantity: "Bestellmenge",
    itemPricePerUnit: "Einzelpreis",
    itemTotal: "Summe",
    itemHeoPositionNumberCompanyCode: "itemHeoPositionNumberCompanyCode",
    itemHeoPositionNumberNumber: "itemHeoPositionNumberNumber",
    itemStatus: "itemStatus",
    itemDiscount: "Rabatt",
    itemTax: "itemTax",
    itemAcceptedQuantity: "Bestätigte Menge",
    shippedQuantity: "Gelieferte Menge",
    itemProductNumber: "Artikelnummer",
    itemProductName: "Artikelbezeichnung",
    gtin: "GTIN",
    orderOrigin: "Herkunft",
    orderOriginValue: {
      API: "Api",
      SHOP: "Shop",
      EDI: "EDI",
    },
    orderType: "Typ",
    orderTypeValue: {
      DROPSHIPPING: "Dropshipping",
      NORMAL: "Normal",
    },
  },
};
